var body = document.querySelector("body");


/* Enable Transitions */
document.addEventListener('DOMContentLoaded', function () {
    setTimeout(function () { document.body.classList.remove("no-transitions"); }, 200);
}, false);


/* Dropdown Menu */
var dropItems = document.querySelectorAll(".main-nav > ul > li");
var mainNav = document.querySelector(".main-nav");
var navButtons = document.querySelectorAll(".header .nav-drop");

var wave = document.querySelector(".feature-wave");
//
    var instance = new SV.HoverIntent(dropItems, {
        onEnter: function(targetItem) {
            // called on mouseenter with intent
            targetItem.querySelector(".nav-header").classList.add("active");
            if(!mainNav.hasAttribute("data-spot")) {
                //vSlideDown(targetItem.querySelector(".nav-drop"), 250);
                console.log("SLIDE DOWN");
                if (wave != null)
                    wave.classList.add("faded");
            }
            else {
                navButtons.forEach(function(ditem) {
                    ditem.style.display = "none";
                });
                targetItem.querySelector(".nav-drop").style.display = "block";
                console.log("SET TO BLOCK");
            }
            mainNav.setAttribute("data-spot",indexInParent(targetItem));
        },
        onExit: function(targetItem) {
            // call on mouseleave after timeout
            if(indexInParent(targetItem) == mainNav.getAttribute("data-spot")) {
                mainNav.removeAttribute("data-spot");
                targetItem.querySelector(".nav-header").classList.remove("active");
                //vSlideUp(targetItem.querySelector(".nav-drop"), 250);
                if (wave != null)
                    wave.classList.remove("faded");
            }
        },
 //   });
/*
    ditem.addEventListener("mouseenter", function (e) {
        mainNav.setAttribute("data-spot",indexInParent(e.target));
        //e.target.parentNode.querySelector(".dropdown-area").style.display = "block";
    }, false);

    ditem.addEventListener("mouseleave", function (e) {
        if(indexInParent(e.target) == mainNav.getAttribute("data-spot")) {
            mainNav.removeAttribute("data-spot");
        }
        //e.target.parentNode.querySelector(".dropdown-area").style.display = "none";
    }, false);

*/

   // menuHover(ditem);
});

function indexInParent(node) {
    var children = node.parentNode.childNodes;
    var num = 0;
    for (var i=0; i<children.length; i++) {
         if (children[i]==node) return num;
         if (children[i].nodeType==1) num++;
    }
    return -1;
}

function menuHover(el) {
    var interval;
    var outInterval;
    var mel = el.querySelector(".dropdown-area");

    el.addEventListener("mouseleave", function (event) {

        interval = setInterval(function () {
            mel.style.display = "none";
        }, 500);
        if (outInterval) {
            clearInterval(outInterval);
        }
    });

    el.addEventListener("mouseover", function (event) {
        outInterval = setInterval(function () {
            mel.style.display = "block";
        }, 500);
        if (interval) {
            clearInterval(interval);
        }
    });
}

document.addEventListener("click", element => {
    /*
    if (element.target.matches(".drop-content a")) {
        mainNav.removeAttribute("data-spot");
        vSlideUp(document.querySelector(".nav-header.active").querySelector(".nav-drop"), 250);
        document.querySelector(".nav-header.active").classList.remove("active");
        if (wave != null)
            wave.classList.remove("faded");
    }
    */
    if (element.target.matches(".mobile-nav .mobile-nav-header")) {
        element.target.parentNode.querySelector(".nav-close").innerHTML = element.target.innerHTML;
        var mn = document.querySelector(".mobile-nav-area").classList.add("open");
        event.target.parentNode.classList.add("active");
    }
    if (element.target.matches(".mobile-nav .nav-close")) {
        var mn = document.querySelector(".mobile-nav-area").classList.remove("open");
        var me = event.target;
        setTimeout(function () { me.parentNode.parentNode.classList.remove("active"); }, 500);
    }
}, false);


/*** MAIN NAV ***/
//hide nav on anchor link of same page click in nav
window.addEventListener("hashchange", function (event) {
    //var drops = document.querySelectorAll(".nav-drop");

    //drops.forEach(function (item) {
    //    item.style.display = "none";
    //});

    if (document.querySelector(location.hash).length > 0) {
        if (document.querySelector(location.hash).classList.contains("js-tab")) {
            var hs = document.querySelector(location.hash).querySelector("button").click();
        }
    }

}, false);


/*** ALERTS ***/
function getCookie(name) {
    let value = `; ${document.cookie}`;
    let parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
    else {
        return null;
    }
}
function setCookie(name, value, days, path, domain, secure) {
    var cookie_string = name + "=" + escape(value);
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    cookie_string += "; expires=" + date.toUTCString();

    cookie_string += "; path=/";
    if (domain)
        cookie_string += "; domain=" + escape(domain);

    if (secure)
        cookie_string += "; secure";

    document.cookie = cookie_string;

    console.log(cookie_string);
}


document.addEventListener("click", element => {
    if (element.target.matches(".js-close-alert")) {
        if (getCookie('alerts')) {
            var cook = getCookie("alerts") + event.target.parentNode.getAttribute("data-alert-id") + "|";
        } else {
            var cook = event.target.parentNode.getAttribute("data-alert-id") + "|"
        }
        //document.cookie = "alerts=" + cook + "; path=/; max-age=" + (60 * 60 * 24 * 14) + ";";
        setCookie("alerts", cook, 14, "/", false, true);

        vSlideUp(element.target.parentNode);
        element.target.parentNode.setAttribute("data-hidden", 'true');
        element.target.parentNode.classList.add('hidden-alert');
        if (document.querySelector('.js-alert-bell')) {
            document.querySelector('.js-alert-bell').style.display = "block";
        }
        
    }
}, false);
let mobile_Header = document.querySelector('.mobile-header');
if (mobile_Header.querySelector('.mobile-sticky-nav')) {
    mobile_Header.querySelector('.mobile-sticky-nav').addEventListener('click', () => {
        if (body.classList.contains('branch-open')) {
            body.classList.remove('branch-open');
        }
    })
}




/*** SCRIPT ANIMATIONS ***/
//let observer = new IntersectionObserver(function (entries) {
//    entries.forEach(function (entry) {
//        // Log if the element and if it's in the viewport
//        if(entry.target.matches(".brick-split")) {

//            if(entry.isIntersecting) {
//                entry.target.timer = setInterval(function() {
//                    entry.target.style.backgroundPosition = (120 - (-20*(entry.target.getBoundingClientRect().top/entry.target.offsetHeight)))+"% 0";
//                }, 30);
//            }
//            else {
//                clearInterval(entry.target.timer);
//            }
//        }
//    });
//});



/*** SCROLL ANIMATIONS ***/
let scrollObserver = new IntersectionObserver(function(entries) {
    entries.forEach(function (entry) {
        if(entry.target.matches(".scroll")) {
            if(entry.isIntersecting) {
                entry.target.classList.add("active");

                /* specific class functions can run here
                if (entry.target.matches(".count")) {
                    animateCountUp(entry.target);
                }
                */
            }
        }
    });
}, { rootMargin: "-100px 0px 0px 0px", threshold: 0.3 });

let scrolls = document.querySelectorAll(".scroll");

scrolls.forEach(function (item) {
    scrollObserver.observe(item);
});

/*** STICKY HEADER ***/
// get the sticky element
const stickyElm = document.querySelector('.headers')

const stickyObserver = new IntersectionObserver(function(entries) {
    entries.forEach(function (entry) {
        if (entry.isIntersecting < 1) {
            //Sticky

            entry.target.classList.remove('unSticky');
            entry.target.classList.remove('wasSticky');
            entry.target.classList.add('wasUnSticky');

            if (!entry.target.classList.contains("isSticky")) {
                setTimeout(function () {
                    entry.target.classList.add('isSticky');
                }, 50);
            }


        }
        else {
            //Non Sticky
            if (entry.target.classList.contains("isSticky")) {
                entry.target.classList.remove('isSticky');
                entry.target.classList.remove('wasUnSticky');
                entry.target.classList.add('wasSticky');

                if (!entry.target.classList.contains("unSticky")) {
                    setTimeout(function () {
                        entry.target.classList.add('unSticky');
                    }, 50);
                }
            }

        }

    });
},
  {threshold: [1]}
);
stickyObserver.observe(stickyElm);



/** STICKY SIDEBAR ***/
var sbObserver = new IntersectionObserver(function(entries) {
    entries.forEach(function (entry) {
        if(entry.boundingClientRect.top < 400) { //if it hits top of viewport
            var sidebarSearch = document.querySelector(".sidebar-search");
            if(entry.intersectionRatio < 1) {
                vSlideDown(sidebarSearch);
            }
            else {
                vSlideUp(sidebarSearch);
            }
        }
    });
},
{rootMargin: "-180px 0px 0px 0px", threshold: [1]});
// The element to observe
let sbs = document.querySelectorAll('.sidebar');
// Attach it to the observer
sbs.forEach(function(item) {
    sbObserver.observe(item);
});


//Escape key Closures
document.onkeydown = function(e) {
    e = e || window.event;
  if (e.key === "Escape" || e.key === "Esc") {
    if(body.classList.contains('search-open')) {
        document.querySelector(".js-search-close").click();
    }
    if(body.classList.contains('login-open')) {
        document.querySelector(".js-login").click();
    }

    if(body.classList.contains('branch-open')){
	document.querySelector('.js-branch-modal-close').click();
    }
  }
};


/** Sync dropdown modals so only one is open at a time **/
function syncModals(activeElement) {

}


/** Search ***/
function openSearch(elem) {
    var btn = document.querySelector(".js-search-area");
    var img = document.querySelectorAll(".js-search img");
    var mb_img = document.querySelector(".search-btn-mobile img");
    var mb_txt = document.querySelector(".search-btn-mobile span");

    //vSlideDown(btn);
    //if (img.length > 0) {
    //    img.forEach(function (mImg) {
    //        mImg.setAttribute("src", "/Content/images/ico-close.svg");
    //    });
    //}

    closeAllMenus();

    body.classList.add("search-open");
    body.classList.remove("search-closed");
    if (body.classList.contains("login-open")) {
        document.querySelector(".js-login-close").click();
    }
    if (document.querySelector(".js-nav-mobile").classList.contains("open")) {
        document.querySelector(".js-nav-mobile").click();
    }
    var searchBox = document.querySelector(".header-search .search-key");
    setTimeout(function () {
        searchBox.focus();
    }, 800);

    if (window.innerWidget < tablet_break) {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    mb_img.src = "/Content/Images/ico-close.svg";
    mb_txt.innerText = "Close";
}
function closeSearch(elem) {
    var btn = document.querySelector(".js-search-area");
    var img = document.querySelectorAll(".js-search img");

    var mb_img = document.querySelector(".search-btn-mobile img");
    var mb_txt = document.querySelector(".search-btn-mobile span");

    //vSlideUp(btn);
    body.classList.remove("search-open");
    body.classList.add("search-closed");
    //if (img.length > 0) {
    //    img.forEach(function (mImg) {
    //        mImg.setAttribute("src", "/Content/images/ico-search.svg");
    //    });
    //}

    mb_img.src = "/Content/Images/ico-search.svg";
    mb_txt.innerText = "Search";
}

/** Login **/
function openLogin(elem) {
    var btn = document.querySelector(".js-login-area");
    var mb_img = document.querySelector(".login-mobile img");
    var mb_txt = document.querySelector(".login-mobile span");
    closeAllMenus();

    body.classList.add("login-open");
    body.classList.remove("login-closed");

    var loginBox = document.querySelector(".personal-form #userid");
    setTimeout(function () {
        loginBox.focus();
    }, 800);
    btn.scrollTop = 0;

    mb_img.src = "/Content/Images/ico-close.svg";
    mb_txt.innerText = "Close";

}
function closeLogin(elem) {
    var btn = document.querySelector(".js-login-area");
    var mb_img = document.querySelector(".login-mobile img");
    var mb_txt = document.querySelector(".login-mobile span");

    

    body.classList.remove("login-open");
    body.classList.add("login-closed");
    mb_img.src = "/Content/Images/ico-lock.svg";
    mb_txt.innerText = "Login";
}

/** Location Modal **/
function openMyBranch(elem) {

}
function closeMyBranch(elem) {

}


/*** Search Window ***/
document.addEventListener("click", async function(e) {

    //Search Open
    if (e.target.matches(".js-search")) {
        var btn = document.querySelector(".js-search-area");

        var img = document.querySelectorAll(".js-search img");

        if (!body.classList.contains("search-open")) {
            openSearch(e.target);
        }
        else {
            closeSearch(e.target);
        }
    }
    //Search Close
    else if (e.target.matches(".js-search-close")) {
        closeSearch(e.target);
    }
    //Login Open
    else if (e.target.matches(".js-login")) {
        var btn = document.querySelector(".js-login-area");
        if (body.classList.contains("login-open")) {
            closeLogin(e.target);
        }
        else {
            openLogin(e.target);
        }

        if (e.target.classList.contains("login-mobile")) {
            var sp = e.target.querySelector("span");
            if (sp.innerText.trim() == "Close") {
                sp.innerText = "Close";
                e.target.classList.add("active");
            }
            else {
                sp.innerText = "Login";
                e.target.classList.remove("active");
            }
        }
    }
    //Login Close
    else if (e.target.matches(".js-login-close")) {
        closeLogin(e.target);
    }
    //Personal Login Tab
    else if (e.target.matches(".js-personal-tab")) {
        document.querySelector(".js-business-tab").classList.remove("active");
        document.querySelector(".js-personal-tab").classList.add("active");

        document.querySelector("#mobile-business-form").classList.remove("active");
        document.querySelector("#mobile-personal-form").classList.add("active");
    }
    //Business Login Tab
    else if (e.target.matches(".js-business-tab")) {
        document.querySelector(".js-business-tab").classList.add("active");
        document.querySelector(".js-personal-tab").classList.remove("active");

        document.querySelector("#mobile-business-form").classList.add("active");
        document.querySelector("#mobile-personal-form").classList.remove("active");
    }
    //Mobile Dropdown/Accordion
    else if (e.target.matches(".js-mobile-dropdown-btn")) {
        var content = e.target.parentNode.querySelector(".js-mobile-dropdown-content");
        var dropButton = e.target;
        if (dropButton.classList.contains("open")) {
            dropButton.classList.remove("open");
            vSlideUp(content);
        }
        else {
            dropButton.classList.add("open");
            vSlideDown(content);
        }
    }
    //Login Mobile Dropdown
    else if (e.target.matches(".js-login-drop")) {

        var btn = e.target.parentNode.querySelector("ul");
        if (btn.style.display != "block") {
            vSlideDown(btn); //.style.display = "block";
            e.target.parentNode.classList.add("active");
        }
        else {
            vSlideUp(btn); //btn.style.display = "none";
            e.target.parentNode.classList.remove("active");
        }
    }
    //Mobile Nav Open
    else if (e.target.matches(".js-nav-mobile")) {
        var btn = document.querySelector(".js-mobile-nav-area");
        closeAllMenus('nav');
        if (e.target.classList.contains("open")) {
            //vSlideUp(btn);
            e.target.querySelector(".menu-text").innerText = "OPEN";
            e.target.classList.remove("open");
            btn.classList.remove("menu-open");
            document.body.classList.remove("nav-open");
            document.querySelector(".js-mobile-nav-area").classList.remove("open");
            document.querySelector("body").classList.remove("menu-open");
        }
        else {
            //vSlideDown(btn);
            e.target.querySelector(".menu-text").innerText = "CLOSE";
            e.target.classList.add("open");
            btn.classList.add("menu-open");
            document.body.classList.add("nav-open");
            document.querySelector("body").classList.add("menu-open");
        }
    }
    //Mobile Nav Close
    else if (e.target.matches(".js-nav-mobile-close")) {
        var btn = document.querySelector(".js-mobile-nav-area");
        btn.classList.remove("menu-open");
        document.body.classList.remove("nav-open");
        // vSlideUp(btn);
    }
    //Footer Nav
    else if (e.target.matches(".footer-nav > ul > li > button")) {
        if (window.innerWidth < tablet_break) {
            e.preventDefault();
            var btn = e.target.parentNode.querySelector("ul");
            if (btn.style.display != "block") {
                vSlideDown(btn); //.style.display = "block";
                e.target.parentNode.classList.add("active");
            }
            else {
                vSlideUp(btn); //btn.style.display = "none";
                e.target.parentNode.classList.remove("active");
            }
        }
    }
    else if (e.target.matches(".js-accordion-header")) {

        var cnt = e.target.parentNode.parentNode.parentNode.querySelector(".js-accordion-content");
        e.target.parentNode.parentNode.parentNode.classList.add("open");
        vSlideDown(cnt);

    }
    else if (e.target.matches(".js-accordion-close")) {

        var cnt = e.target.parentNode.parentNode.parentNode.querySelector(".js-accordion-content");
        e.target.parentNode.parentNode.parentNode.classList.remove("open");
        vSlideUp(cnt);

    }
    else if (e.target.matches("[data-video]") && !e.target.parentNode.classList.contains("cta-buttons") && e.target.parentNode.id != "ctaButtons") {
        if (e.target.getAttribute("data-video") != "") {
            e.preventDefault();
            let videoTitle = "";
            let videoGUID = "";
            if (e.target.querySelector("span.visuallyhidden").length > 0 && e.target.querySelector("span.visuallyhidden").innerText != "") {
                videoTitle = e.target.querySelector("span.visuallyhidden").innerText;
            }
            var vidBuild = "<div class='video-modal-content'><button class='video-modal-close'>&times;<span class='visuallyhidden'> close</span></button>";
            if (e.target.getAttribute("data-video").indexOf("vimeo") != -1) {
                var vid = e.target.getAttribute("data-video").replace("https://vimeo.com/", "");
                if (vid.indexOf("/") != -1) {
                    vid = vid.substring(0, vid.indexOf("/"));
                }
                vidBuild += "<div style='padding: 56.25% 0 0 0; position: relative;'><iframe src='https://player.vimeo.com/video/" + vid + "?title=0&byline=0&portrait=0' alt='" + videoTitle + "' style='position:absolute;top:0;left:0;width:100%;height:100%;' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe></div><script src='https://player.vimeo.com/api/player.js'></script>";
                vidBuild += "<div class='transcript-container js-accordion'><button class='transcript-button js-accordion-button' type='button' aria-expanded='false'>Video Transcript <img src='/Content/images/arrow-down.svg' width='19' height='10' alt='' /></button><div class='transcript-content js-accordion-content'><p>This video uses Vimeo's native closed captioning features to provide a transcript. If closed captioning is unavailable for this video, please <a href='/contact'>contact us</a> and we can provide you with one.</p></div></div>";
            }
            else if (e.target.getAttribute("data-video").indexOf("youtube") != -1) {
                var vid = e.target.getAttribute("data-video").replace("https://www.youtube.com/watch?v=", "");
                if (vid.indexOf("/") != -1) {
                    vid = vid.substring(0, vid.indexOf("/"));
                }
                vidBuild += "<div style='padding: 56.25% 0 0 0; position: relative;'><iframe src='https://www.youtube.com/embed/" + vid + "' alt='" + videoTitle + "' style='position:absolute;top:0;left:0;width:100%;height:100%;' frameborder='0' allow='accelerometer; autoplay; encrypted - media; gyroscope; picture -in -picture; fullscreen' allowfullscreen></iframe></div>";
                vidBuild += "<div class='transcript-container js-accordion'><button class='transcript-button js-accordion-button' type='button' aria-expanded='false'>Video Transcript <img src='/Content/images/arrow-down.svg' width='19' height='10' alt='' /></button><div class='transcript-content js-accordion-content'><p>This video uses YouTube's native closed captioning features to provide a transcript. If closed captioning is unavailable for this video, please <a href='/contact'>contact us</a> and we can provide you with one.</p></div></div>";
            }
            else if (e.target.getAttribute("data-video").indexOf(".mp4")) {
                videoGUID = e.target.getAttribute("data-video").split("/")[2];
                vidBuild += "<video width='100%' controls autoplay><source src='" + e.target.getAttribute("data-video") + "' type='video/mp4'></video>";
                // Since the transcript is queried async, make a holder for it and we'll append the content after the async completes
                vidBuild += "<div class='transcript-container js-accordion'><button class='transcript-button js-accordion-button' type='button' aria-expanded='false'>Video Transcript <img src='/Content/images/arrow-down.svg' width='19' height='10' alt='' /></button><div id='transcript-" + videoGUID + "' class='transcript-content js-accordion-content'><p>For a transcript of this video, please <a href='/contact'>contact us</a>.</p></div></div>";
            }
            vidBuild += "</div>";

            var vidModal = document.querySelector(".video-modal");
            if (vidModal !== null) {
                vidModal.parentNode.removeChild(vidModal);
            }

            var div = document.createElement("div");
            div.classList.add("video-modal");
            div.innerHTML = vidBuild;

            document.body.appendChild(div);

            if (videoGUID != "" && document.getElementById("transcript-" + videoGUID) != undefined) {
                // If it's embedded, get the transcript
                let xhr = new XMLHttpRequest();
                xhr.open("GET", "/transcript/" + videoGUID);
                xhr.send();
                xhr.onload = function () {
                    if (xhr.status === 200 && xhr.responseText.replace(/\"/gm, "") != "") {
                        // console.log("Transcrpt Retrieval Succeeded, Status: " + xhr.status + ".");
                        document.getElementById("transcript-" + videoGUID).innerHTML = "<p>" + xhr.responseText.replace(/\"/gm, "").replace(/\\r\\n/gm, "<br />") + "</p>";
                    } else {
                        // console.log("Transcrpt Retrieval Failed, Status: " + xhr.status + ".");
                    }
                }
                xhr.onerror = function () {
                    // console.log("Transcript Retrieval Error: " + xhr.status + ".");
                }
            }
        }
    }
    else if (e.target.matches(".video-modal")) {
        e.target.parentNode.removeChild(e.target);
    }
    else if (e.target.matches(".video-modal-content")) {
        e.stopPropagation();
    }
    else if (e.target.matches(".video-modal-close")) {
        e.preventDefault();
        var vidModal = document.querySelector(".video-modal");
        if (vidModal !== null) {
            vidModal.parentNode.removeChild(vidModal);
        }
    }
    else if (e.target.matches(".js-tab-header")) {
        //change tab

        var tabs = document.querySelectorAll(".js-tab");
        tabs.forEach(function (tab) {
            if (tab.getAttribute("data-tab") == e.target.getAttribute("data-tab")) {
                tab.classList.add("active");
            }
            else {
                tab.classList.remove("active");
            }
        });
        var headers = document.querySelectorAll(".js-tab-header");
        headers.forEach(function (tab) {
            if (tab.getAttribute("data-tab") == e.target.getAttribute("data-tab")) {
                tab.classList.add("active");
            }
            else {
                tab.classList.remove("active");
            }
        });

    }
    else if (e.target.matches(".tab-name")) {
        //change tab

        var tab = e.target.parentNode.querySelector(".tab-content");

        if (tab.classList.contains("tab-open")) {
            vSlideUp(tab);
            tab.classList.remove("tab-open");
            e.target.classList.remove("open");
        }
        else {
            vSlideDown(tab);
            tab.classList.add("tab-open");
            e.target.classList.add("open");
        }

    }
    else if (e.target.matches(".js-branch-selector")) {

        closeAllMenus();

        document.body.classList.add("branch-open");
    }
    else if (e.target.matches(".js-branch-modal-close")) {

        document.body.classList.remove("branch-open");
    }
    //else if (e.target.matches(".js-branch-modal")) {
    //    //Hide Locations Modal
    //    if (e.target.id == "locationsModal") {

    //        document.body.classList.remove("branch-open");
    //    }
    //}
    else if (e.target.matches(".js-location")) {

        //Update for actual locations - save location and id as cookies
        var cook = event.target.getAttribute("data-name");
        var cookID = event.target.getAttribute("data-id");
        const state = event.target.getAttribute("data-state");
        document.cookie = "locationID=" + cookID + "; path=/; max-age=" + (60 * 60 * 24 * 14) + ";";
        document.cookie = "locationName=" + cook + "; path=/; max-age=" + (60 * 60 * 24 * 14) + ";";
        document.cookie = "locationState=" + state + "; path=/; max-age=" + (60 * 60 * 24 * 14) + ";";

        window.location.reload();

    }
    else if (e.target.matches(".js-switch-branch")) {

        fetch("/Locations/ModalList")
            .then(response => response.text())
            .then(data => {
                var branches = document.querySelector(".js-modal-body");
                branches.innerHTML = data;
            }).catch(error => {
                // Handle error
            });
    }
    else if (e.target.matches(".js-employee-modal")) {

        fetch("/Directory/DirectoryModal?nodeAlias=" + e.target.getAttribute("data-alias"))
            .then(response => response.text())
            .then(data => {
                var branches = document.querySelector(".js-employee-modal-area");
                if (branches) {
                    var con = branches.querySelector("content");
                    if (con) {
                        con.innerHTML = data;
                    }
                    branches.classList.add("open");
                }
                else {
                    var mod = document.createElement("div");
                    mod.classList.add("js-employee-modal-area");
                    mod.classList.add("directory-modal");
                    mod.classList.add("open");
                    var modInner = document.createElement("div");
                    modInner.classList.add("content");
                    modInner.innerHTML = data;
                    mod.appendChild(modInner);
                    document.body.appendChild(mod);
                }

            }).catch(error => {
                // Handle error
            });
    }
    else if (e.target.matches(".js-close-employee")) {

        //Blog subscribe box on mobile
        document.querySelector(".js-employee-modal-area").classList.remove("open");
    }
    else if (e.target.matches(".js-close-employee > .content")) {

        //Blog subscribe box on mobile
        e.stopPropagation();
    }
    else if (e.target.matches(".js-employee-modal-area")) {

        //Blog subscribe box on mobile
        document.querySelector(".js-employee-modal-area").classList.remove("open");
    }
    else if (e.target.matches(".js-sidebar-btn")) {

        //Blog subscribe box on mobile
        vSlideUp(e.target);
        vSlideDown(e.target.parentNode.querySelector(".sidebar-content"));
    }
    else if (e.target.matches(".js-sidebar-close-btn")) {

        //Blog subscribe box on mobile
        vSlideUp(e.target.parentNode);
        vSlideDown(e.target.parentNode.parentNode.querySelector(".js-sidebar-btn"));
    }
    else if (e.target.matches(".js-alert-bell")) {

        var alerts = document.querySelectorAll(".hidden-alert");
        document.cookie = "alerts=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        alerts.forEach(function (alert) {
            vSlideDown(alert);
        });
        e.target.style.display = 'none';

    }
    else if (e.target.matches(".js-bc-video")) {
        //document.querySelector(".js-bc-video").parentNode.parentNode.parentNode.querySelector("img").click();
        e.target.parentNode.parentNode.parentNode.querySelector(".photo img").click();


    }
    else if (e.target.matches(".jump-lnk")) {
        var lnkText = e.target.innerText.replace(/ /g, '_');
        var btn = document.querySelectorAll("[data-jumplink='" + lnkText + "']");
        if (btn.length > 0) {

            btn[0].scrollIntoView({ behavior: "smooth" });

            //Check if tab
            var tab = getParentElementWithClass(btn[0], ".tab-area");
            if (tab != null) {
                var tb = tab.document.querySelector(".tab-name");
                var tabBtns = document.querySelectorAll(".js-tab-header");
                tabBtns.forEach(function (item) {
                    if (item.innerText.trim() == tb.innerText.trim()) {
                        item.click();
                        item.scrollIntoView({ behavior: "smooth" });
                    }
                });
            }
        }
        var cls = e.target.parentNode.parentNode.querySelector(".js-close-jump");
        if (cls) {
            cls.click();
        }

        
    }
    // Comparison Chart Mobile Previous
    else if (e.target.matches(".js-table-prev")) {
        var base = e.target.parentNode.parentNode; //$(this).parent().parent();
        base.querySelector(".js-table-next").classList.remove("grayed");
        base.setAttribute("data-col", Number(base.getAttribute("data-col")) - 1);
        if (base.getAttribute("data-col") == 1) {
            e.target.classList.add("grayed");
        }
        var spot = base.querySelector("table tr");
        var ths = spot.querySelectorAll("th");
        base.querySelector(".js-rate-head").innerHTML = (eq.call(ths, base.getAttribute("data-col")).innerHTML);
    }
    // Comparison Chart Mobile Next
    else if (e.target.matches(".js-table-next")) {

        var base = e.target.parentNode.parentNode; //$(this).parent().parent();
        base.querySelector(".js-table-prev").classList.remove("grayed");
        base.setAttribute("data-col", Number(base.getAttribute("data-col")) + 1);
        var spot = base.querySelector("table tr");
        var ths = spot.querySelectorAll("th");

        if (base.getAttribute("data-col") == ths.length - 1) {
            e.target.classList.add("grayed");
        }

        base.querySelector(".js-rate-head").innerHTML = (eq.call(ths, base.getAttribute("data-col")).innerHTML);
    }
    else if (e.target.matches(".bl-current")) {
        let locations = document.querySelectorAll('.branch-modal-select .branches .location')
        if (navigator.geolocation) {
            document.querySelector('.bl-current').innerText = "Please allow browser to use location";
            navigator.geolocation.getCurrentPosition((position) => {
		        locations = determineDistance(locations, position.coords.latitude, position.coords.longitude);
                let sortedLocations = []
                locations.forEach((loc) => {
                    loc.dataset.distance += ' Miles Away';
                    sortedLocations.push(loc);
                })
                sortedLocations = sortList(sortedLocations);
                console.log(sortedLocations);
                const destination = document.querySelector('.branch-modal-select .branches');
                destination.innerHTML = ""
                sortedLocations.forEach((el) => {
                    addElement(el, destination)
                });
                var first = document.querySelector('.branch-modal-select .branches .location');
                if (first) {
                    first.querySelector(".js-location").click();
                }
                document.querySelector('.bl-current').innerText = "Use my current location";
            })
        } else {
	    document.querySelector('.current-location-status').innerText = "Cannot use location services.";
	}
    }
    else if (e.target.matches('.js-triggerLogin')) {
        e.preventDefault();
        if (document.querySelector('.js-login')) {
            document.querySelector('.js-login').click();
        }
    }
    else if (e.target.matches('.background-overlay')){
	    if (body.classList.contains('login-open')){
	        document.querySelector('.js-login-close').click();
	    }
	
	    if (body.classList.contains('search-open')){
		    document.querySelector('.js-search-close').click();
	    }	
	
	    if (body.classList.contains('branch-open')){
		    document.querySelector('.js-branch-modal-close').click();
	    }
    }

}, false);






/** Tabs **/
var tabs = document.querySelectorAll(".tab-area.js-tab");
//Create Tab Nav
var tabHeader = document.createElement("div");
tabHeader.classList.add("tabs-nav");
var cnt = document.createElement("div");
cnt.classList.add("content");
tabs.forEach(function(tab) {
    var rand = Math.floor(Math.random()*10000000000000);
    var btn = document.createElement("button");
    btn.classList.add("js-tab-header");
    btn.setAttribute("data-tab",rand);
    tab.setAttribute("data-tab",rand);
    btn.innerHTML = tab.querySelector(".tab-name").innerHTML;
    tab.querySelector(".tab-name").style.display = "none";
    cnt.appendChild(btn);
});
tabHeader.appendChild(cnt);

if(tabs.length > 0) {
    tabs[0].parentNode.insertBefore(tabHeader,tabs[0]);
}


//Set first as active
var tabBtns = document.querySelectorAll(".js-tab-header");
if(tabBtns.length > 0) {
    tabBtns[0].classList.add("active");
    tabs[0].classList.add("active");
}

//Allow setting of tab via location hash
if (window.location.hash) {
    var hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character

    tabBtns.forEach(function (topic) {
        if (topic.innerText.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "") == hash.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "")) {
            topic.click();
        }
    });
}
if (tabs) {
    for (const t of tabs) {
        if (t.querySelector('.tab-name > img')) {
            t.classList.add('hasImage');
        }
    }
}


/* Anchor Links Chrome Fix */
(function (document, history, location) {
    var HISTORY_SUPPORT = !!(history && history.pushState);

    var anchorScrolls = {
        ANCHOR_REGEX: /^#[^ ]+$/,
        OFFSET_HEIGHT_PX: 65,

        /**
         * Establish events, and fix initial scroll position if a hash is provided.
         */
        init: function () {
            this.scrollToCurrent();
            window.addEventListener('hashchange', this.scrollToCurrent.bind(this));
            document.body.addEventListener('click', this.delegateAnchors.bind(this));
        },

        /**
         * Return the offset amount to deduct from the normal scroll position.
         * Modify as appropriate to allow for dynamic calculations
         */
        getFixedOffset: function () {

            return this.OFFSET_HEIGHT_PX;
        },

        /**
         * If the provided href is an anchor which resolves to an element on the
         * page, scroll to it.
         * @param  {String} href
         * @return {Boolean} - Was the href an anchor.
         */
        scrollIfAnchor: function (href, pushToHistory) {
            var match, rect, anchorOffset;

            if (!this.ANCHOR_REGEX.test(href)) {
                return false;
            }

            match = document.getElementById(href.slice(1));

            if (match) {
                rect = match.getBoundingClientRect();
                anchorOffset = window.pageYOffset + rect.top - this.getFixedOffset();
                window.scrollTo(
                {
                    top: anchorOffset,
                        left: window.pageXOffset,
                            behavior: 'smooth'
                });

                // Add the state to history as-per normal anchor links
                if (HISTORY_SUPPORT && pushToHistory) {
                    history.pushState({}, document.title, location.pathname + href);
                }
            }

            return !!match;
        },

        /**
         * Attempt to scroll to the current location's hash.
         */
        scrollToCurrent: function () {
            this.scrollIfAnchor(window.location.hash);
        },

        /**
         * If the click event's target was an anchor, fix the scroll position.
         */
        delegateAnchors: function (e) {
            var elem = e.target;

            if (
                elem.nodeName === 'A' &&
                this.scrollIfAnchor(elem.getAttribute('href'), true)
            ) {
                e.preventDefault();
            }
        }
    };

    window.addEventListener(
        'DOMContentLoaded', anchorScrolls.init.bind(anchorScrolls)
    );
})(window.document, window.history, window.location);


/** CAROUSELS **/

document.addEventListener('DOMContentLoaded', function () {
    var carousels = document.querySelectorAll(".splide");

    carousels.forEach((car) => {
        if (getParentElementWithClass(car, "block-testimonial-carousel")) {
            var splide = new Splide(car, {
                type: 'loop',
            });
        } else {
            var splide = new Splide('.splide', {
                perPage: 6,
                type: 'loop',
                drag: 'free',
                focus: 'center',
                padding: '5rem',
                gap: '30px',
                breakpoints: {
                    980: {
                      perPage: 1
                    },
                }
            });
        }
        splide.mount();
    })

    var carousels = document.querySelectorAll(".splideFull");
    if (carousels.length > 0 && document.querySelectorAll("html.hydrated").length == 0) {
        var splide = new Splide('.splideFull', {
            perPage: 1,
            type: 'loop',
            drag: false,
            focus: 'center',
            breakpoints: {
                980: {

                    drag: true,
                },
            },
        });
        splide.mount();
    }

    var carousels = document.querySelectorAll(".splideAuto");
    if (carousels.length > 0) {
        const splider = new Splide('.splideAuto', {
            type: 'loop',
            drag: 'free',
            focus: 'center',
            padding: '5rem',
            gap: '30px',
            pagination: false,
            arrows: false,
            autoWidth: true,
            autoScroll: {
                speed: 1,
            },
            breakpoints: {
                980: {
                  padding: '2rem',
                  perPage: 1,
            autoWidth: false,
                },
            },
        });
        splider.mount(window.splide.Extensions);
    }

    //COOKIE LOCATION
    if(getCookie("locationID") != null) {
        var locs = document.querySelectorAll(".js-current-branch");

        locs.forEach(function (loc) {
            if (loc.parentElement.classList.contains('my-branch-mobile')) {
                loc.innerHTML = "<strong>My Banking Center - </strong>" + getCookie('locationName');
            } else {
                loc.innerText = getCookie("locationName");
            }
            
            loc.setAttribute("data-id", getCookie("locationID"));
        });
        /*
        fetch("/Locations/ModalDetail?locationID=" + getCookie("locationID"))
            .then(response => response.text())
            .then(data => {
                var branches = document.querySelector(".js-modal-body");
                branches.innerHTML = data;
            }).catch(error => {
                // Handle error
            });
            */
    }


    //Compare Chart Highlight
    var cmp = document.querySelectorAll(".compare-table");

    if(cmp.length > 0) {
        var pageTitle = document.querySelector("h1");
        if(pageTitle != null) {
            cmp.forEach(function(table) {
                var ths = table.querySelectorAll("th");
                ths.forEach(function(th) {
                    if(th.innerText == pageTitle.innerText) {
                        var cellIndex = th.cellIndex;

                        var tds = table.querySelectorAll("th,td");
                        tds.forEach(function(td) {
                            if(td.cellIndex == cellIndex) {
                                td.classList.add("active");
                            }
                        });
                    }
                })
            });
        }
    }
});


/**
 * ADA - Remove id=pasted
 */
var ids = document.querySelectorAll("#isPasted");
ids.forEach(function (item) {
    item.removeAttribute('id');
});

/** Comparison Charts
 * Add controls on mobile **/
function eq(index) {
    if (index >= 0 && index < this.length)
        return this[index];
    else
        return -1;
}

var charts = document.querySelectorAll(".comparison-chart");

charts.forEach(function (chart) {
    var tab = chart.querySelector("table");

    var holder = chart.querySelector(".compare-table");
    var ths = tab.querySelector("tr").querySelectorAll("th");
    if (ths.length > 1) {
        holder.setAttribute("data-col", "1");
        var controls = document.createElement("div");
        controls.classList.add("table-controls");
        controls.innerHTML = "<button class='js-table-prev grayed'></button><span class='f-h2 js-rate-head'>" + eq.call(ths, 1).innerHTML + "</span><button class='js-table-next'></button>";

        holder.insertBefore(controls, holder.firstChild);
    }


});


/** JUMP NAVS ***/
var jumpObserver = new IntersectionObserver(function (entries) {
    entries.forEach(function (entry) {
        if (entry.target.matches(".jump-nav")) {
            if (entry.boundingClientRect.top < 200) { //if it hits top of viewport
                entry.target.classList.toggle('sticky', entry.intersectionRatio < 1);
              }
        }
    });
},
    { rootMargin: "-50px 0px 0px 0px", threshold: [1] });
// The element to observe
let jumps = document.querySelectorAll('.jump-nav');
// Attach it to the observer
jumps.forEach(function (item) {
    jumpObserver.observe(item);
});

var jumpHolder = document.querySelector(".js-links");

var jumpNavObserver = new IntersectionObserver(function (entries) {
    entries.forEach(function (entry) {
        if (entry.target.matches("[data-jumplink]")) {

            if (entry.isIntersecting) {
                var jumps = document.querySelectorAll(".js-links .jump-lnk");
                jumps.forEach(function (jp) {
                    if (jp.innerText == entry.target.getAttribute("data-jumplink").replace(/_/g, " ")) {
                        jp.classList.add("active");
                        if (window.innerWidth < tablet_break) {
                            jumpHolder.scrollTo({
                                top: 0, left: (jp.offsetLeft - 70), behavior: 'smooth'
                            });
                        }
                    }
                    else {
                        jp.classList.remove("active");
                    }
                });
            }
        }
    });
},
    { rootMargin: "0px 0px 0px 0px", threshold: [.1] });
//Current jump link highlight
jumps = document.querySelectorAll('[data-jumplink]');
// Attach it to the observer
jumps.forEach(function (item) {
    jumpNavObserver.observe(item);
});

//Cross Sell Logic
const crossSellBlocks = document.querySelectorAll('.js-crossSell');
const crossSellContainer = document.querySelector('.cross-sell');
let activeCrossSell = 0;

if (crossSellBlocks.length > 0) {

    window.addEventListener("load", sizeCrossSells, false);
    window.addEventListener("resize", sizeCrossSells, false);

    function sizeCrossSells() {
        var crs = document.querySelectorAll(".js-crossSell");
        var height = 250;

        crs.forEach(function (cs) {
            if (cs.offsetHeight > height) {
                height = cs.offsetHeight;
            }
        });
        if (crs.length < 2) {
            height = height - 40;
        }
        document.querySelector(".CS-container").style.height = height + "px";
    }
    sizeCrossSells();

    function createDotsandCrossSells(index) {
        const dotBox = document.querySelector('.nav-dots');
        dotBox.innerHTML = '';
        for (let i = 0; i < crossSellBlocks.length; i++) {
            const dot = document.createElement('button');
            dot.classList.add('dot');
            if (i == index) {
                dot.classList.add('active')
            };
            dot.dataset.index = i;
            dotBox.appendChild(dot);
        }
        crossSellBlocks[0].classList.add('active');
        crossSellBlocks[1].classList.add('on-deck');
    }

    function UpdateDots(index) {
        for (dot of document.querySelectorAll('.nav-dots .dot')) {
            if (dot.dataset.index == index) {
                dot.classList.add('active')
            } else {
                dot.classList.remove('active');
            }
        }
    }

    function cycleCrossSells(index) {
        console.log(index);
        // for (x of crossSellBlocks) {
        //   if (x.classList.contains('active')) {
        //     x.classList.add('fadeOut');
        //   }
        //   x.classList.remove('on-deck');
        //   x.classList.remove('active');
        //   if (crossSellContainer.classList.contains('right')) {
        //     if (x.dataset.index == index) {
        //       x.classList.add('active');
        //     } else if (x.dataset.index == index + 1) {
        //       x.classList.add('on-deck');
        //     }
        //     if (index == (crossSellBlocks.length -1)) {
        //       crossSellBlocks[0].classList.add('on-deck');
        //     }
        //   }
        // }
        // if (crossSellContainer.classList.contains('left')) {
        var oldActive = document.querySelectorAll(".js-crossSell.active");
        oldActive.forEach(function (item) {
            item.classList.remove("active");
        });

        crossSellBlocks[index].classList.add('active');
        crossSellBlocks[index].classList.remove('on-deck');
        crossSellBlocks[index].classList.remove('fadeOut');
        var old = document.querySelectorAll(".on-deck");
        old.forEach(function (item) {
            item.classList.remove("on-deck");
        });
        crossSellBlocks[(index - 2 + crossSellBlocks.length) % crossSellBlocks.length].classList.add('on-deck');
        crossSellBlocks[(index - 2 + crossSellBlocks.length) % crossSellBlocks.length].classList.remove('active');
        crossSellBlocks[(index - 2 + crossSellBlocks.length) % crossSellBlocks.length].classList.remove('fadeOut');

        crossSellBlocks[(index - 1 + crossSellBlocks.length) % crossSellBlocks.length].classList.add('fadeOut');
        crossSellBlocks[(index - 1 + crossSellBlocks.length) % crossSellBlocks.length].classList.remove('on-deck');
        crossSellBlocks[(index - 1 + crossSellBlocks.length) % crossSellBlocks.length].classList.remove('active');
        // }
    }

    const prevArrow = document.querySelector('.nav .prev-arrow');
    const nextArrow = document.querySelector('.nav .next-arrow');
    if(prevArrow){
	
	prevArrow.addEventListener('click', () => {
	    crossSellContainer.classList.add('left');
	    crossSellContainer.classList.remove('right');
	    const fadedEl = document.querySelector('.fadeOut');
	    if (fadedEl) fadedEl.classList.remove('fadeOut');
	    if (activeCrossSell > 0) {
		--activeCrossSell;
	    } else {
		activeCrossSell = crossSellBlocks.length - 1;
	    }
	    UpdateDots(activeCrossSell);
	    cycleCrossSells(activeCrossSell);
	})
	nextArrow.addEventListener('click', () => {
	    crossSellContainer.classList.add('right');
	    crossSellContainer.classList.remove('left');
	    const fadedEl = document.querySelector('.fadeOut');
	    if (fadedEl) fadedEl.classList.remove('fadeOut');
	    if (activeCrossSell < crossSellBlocks.length - 1) {
		++activeCrossSell;
	    } else {
		activeCrossSell = 0;
	    }
	    UpdateDots(activeCrossSell);
	    cycleCrossSells(activeCrossSell);
	})
    }

    if (crossSellBlocks.length > 1) {
	createDotsandCrossSells(activeCrossSell);
	
    } else {
	crossSellBlocks[0].classList.add('active')
    }
    // cycleCrossSells(activeCrossSell);
}
    



if (document.querySelectorAll(".js-calc").length > 0) {
    document.addEventListener("click", function (e) {
        if (e.target.matches(".js-calc")) {
            var sc = document.createElement("script");
            sc.src = "https://www.calcxml.com/scripts/loadCalc.js?calcTarget=" + e.target.getAttribute("data-calcid") + "&skn=736&embed=2&tabs=1&secure=1";
            document.body.appendChild(sc);

            document.querySelectorAll(".js-calc.active").forEach(function (item) {
                item.classList.remove("active");
            });
            e.target.classList.add("active");
        }
    }, false);

    document.querySelector(".js-calc").click();
}


//Alerts js
//document.addEventListener('DOMContentLoaded', function () {
//    var alertHeight = 0;
//    var alerts = document.querySelector(".alerts-holder");

//    if (alerts) {
//        alertHeight = alerts.offsetHeight;
//    }
//    var padds = document.querySelectorAll(".flyout-pad");
//    padds.forEach(function (item) {
//        item.style.paddingTop = Math.floor(140 + alertHeight) + "px";
//    });
//}, false);



//Horizontal filter scrolling
if (document.querySelector('.js-horizontalScroll') != null) {
    for (el of document.querySelectorAll('.js-horizontalScroll')) {
        
        if (!el.getAttribute('style')) {
            el.setAttribute('style', "--beforeElementOpacity: 0; --afterElementOpacity: 1");
        }
        el.addEventListener('scroll', () => {
            if (event.target.scrollLeft == 0) {
                event.target.setAttribute('style', "--beforeElementOpacity: 0; --afterElementOpacity: 1");
                //document.documentElement.style.setProperty('--beforeElementOpacity', '1');
            } else if (event.target.scrollLeft + event.target.offsetWidth == event.target.scrollWidth) {
                event.target.setAttribute('style', "--afterElementOpacity: 0; --beforeElementOpacity: 1;");
                //document.documentElement.style.setProperty('--afterElementOpacity', '0');
            } else {
                event.target.setAttribute('style', "--afterElementOpacity: 1; --beforeElementOpacity: 1;");
                //document.documentElement.style.setProperty('--afterElementOpacity', '1');
            }
        });
    }
}

//Close all menus when opening a new one.
function closeAllMenus(target = "") {
    if (mobile_Header) {
        closeSearch();
        closeLogin();
        closeMyBranch();
        if (body.classList.contains('nav-open') && target != 'nav') {
            document.querySelector('.js-nav-mobile').click();
        }
    }
}

function sortBranches(addressLat, addressLon, locationClass = '.location') {
    //Get distance from address for each branch
    document.querySelectorAll(locationClass).forEach(function (loc) {
        if (loc.getAttribute("data-latlon") != null && loc.getAttribute("data-latlon").indexOf(",") != -1) {
            var sp = loc.getAttribute('data-latlon').split(',');
            lat = Number(sp[0].trim());
            lon = Number(sp[1].trim());
            dist = distance(addressLat, addressLon, lat, lon);
            loc.setAttribute('data-distance', dist);
            loc.querySelector(".js-distance").textContent = (Math.round(dist * 10) / 10) + " Miles Away";
        }
    });
    

    //Sort items based on distance
    Array.from(document.querySelectorAll(".location[data-distance]")).
        sort(function (a, b) {
            if (Number(a.dataset.distance.replace(" Miles Away", "")) < Number(b.dataset.distance.replace(" Miles Away", ""))) {
                return -1;
            }
            else {
                return 1;
            }
        })
        .forEach((item) => {
            item.parentNode.appendChild(item)
        });

    filterBranches("all");

    var tb = document.querySelector(".branch-tab");
    tb.classList.add("map-open");

}

function distance(lat1, lon1, lat2, lon2) {
    if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0;
    } else {
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lon1 - lon2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        return dist;
    }
}
function filterBranches(filter) {
    var locs = document.querySelectorAll(".branch-tab-content .location");

    var count = 0;

    locs.forEach(function (loc) {
        if (filter == "all" || loc.getAttribute("data-services").indexOf(filter) != -1) {
            loc.style.display = "block";
            count++;
            if (count % 2 == 0) {
                loc.classList.add("spacer");
            }
        }
        else {
            loc.style.display = "none";
        }
    });
}
filterBranches("all");

//Closest Branch logic
async function displayClosest() {

    if (document.querySelector('.js-closest-locations')) {
        let closestLocations = [];
        const destination = document.querySelector('.js-closest-locations');

        const allLocations = document.querySelectorAll('.js-location-data');
        
        if (getCookie('locationID')) {
            const locationCookie = getCookie("locationID");
            let cookieLatLon;
            let homeLocation;
            allLocations.forEach((element, id) => {
                if (element.dataset.locationid == locationCookie) {
                    //const el = Array.from(allLocations).splice(id, 1);
                    //closestLocations.unshift(el);
                    homeLocation = element;
                    cookieLatLon = homeLocation.dataset.latlon.split(',');
                } else {
                    closestLocations.push(allLocations[id])
                }
            })
            for (let x = 0; x < closestLocations.length; x++) {
                if (!closestLocations[x].querySelector(`[data-locationid="${locationCookie}"]`)) {
                    addElement(closestLocations[x], destination);
                }
            }
            let locations = [];
            document.querySelectorAll('.js-closest-locations > .js-location-data').forEach((el) => locations.push(el));
            let sortedLocations;
            locations = determineDistance(locations, cookieLatLon[0], cookieLatLon[1]);
            sortedLocations = sortList(locations);
            destination.innerHTML = '';
            sortedLocations.forEach((el, id) => {
                if (id < 2) {
                    addElement(el, destination);
                }
            })
            addElement(homeLocation, destination, true);
            
        } else {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(getLocationsByIP);
            }
        }

    }
    
}

function getLocationsByIP(position) {
    const destination = document.querySelector('.js-closest-locations');
    let allLocations = document.querySelectorAll('.js-location-data');
    allLocations.forEach((el, x) => {
        addElement(el, destination);
    })
    let locations = [];
    document.querySelectorAll('.js-closest-locations > .js-location-data').forEach((el) => locations.push(el));
    let sortedLocations;
    /*await sortBranches(position.coords.latitude, position.coords.longitude, '.js-closest-locations > .js-location-data');*/
    /*console.log(locations);*/
    locations = determineDistance(locations, position.coords.latitude, position.coords.longitude);
    sortedLocations = sortList(locations);

    destination.innerHTML = '';
    sortedLocations.forEach((el, id) => {
        if (id < 3) {
            addElement(el, destination);
        }
    })
}

function addElement(el, container, prepend = false) {
    let div = document.createElement("div");
    div.innerHTML = el.innerHTML;
    div.classList = el.classList;
    div.dataset.latlon = el.dataset.latlon;
    if (prepend) {
        container.prepend(div);
    } else {
        container.appendChild(div);
    }
}

function sortList(arrayToSort) {
    arrayToSort.
        sort(function (a, b) {
            if (Number(a.dataset.distance.replace(" Miles Away", "")) < Number(b.dataset.distance.replace(" Miles Away", ""))) {
                return -1;
            }
            else {
                return 1;
            }
        })
    return arrayToSort;
}

function determineDistance(nodeArray, addressLat, addressLon) {
    Array.from(nodeArray).forEach(function (loc) {
        if (loc.getAttribute("data-latlon") != null && loc.getAttribute("data-latlon").indexOf(",") != -1) {
            var sp = loc.getAttribute('data-latlon').split(',');
            lat = Number(sp[0].trim());
            lon = Number(sp[1].trim());
            dist = distance(addressLat, addressLon, lat, lon);
            loc.setAttribute('data-distance', dist);
            if (loc.querySelector('.js-distance')) {
                loc.querySelector(".js-distance").textContent = (Math.round(dist * 10) / 10) + " Miles Away";
            }
           
        }
    });
    return nodeArray;
}


document.addEventListener('DOMContentLoaded', () => {
    displayClosest();
});
    
var cookieNotice = document.querySelector(".cookie-notice");

document.addEventListener('DOMContentLoaded', function () {
    document.addEventListener("click", function (e) {
        if (e.target.matches(".hide-message")) {
            var now = new Date().getTime();
            var NINETY_DAYS = 90 * 24 * 60 * 60 * 1000;
            var ninetyDaysFromNow = now + NINETY_DAYS;

            setCookie("closedBanner", "yes", 90, "/", false, true);

            cookieNotice.style.display = "none";
        }
    });

    if (getCookie("closedBanner") == "yes") {
        cookieNotice.style.display = "none";
    }
}, false);
//const handleSearchSubmit = (e) => {
//    e.preventDefault();
//    alert('Set up the google thing');
//}
//document.addEventListener('submit', (e) => {
//    if (e.target[0].classList.contains('search-box')) {
//	handleSearchSubmit(e);
//    }
//})




/** Branch Modal Location Search **/

function encodeLocationHTML(s) {
    return s.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/"/g, '&quot;');
}

function branchInit() {

    //Run the location page maps function if on that page
    if (typeof initialize === 'function') {
        initialize();
    }

    //Branch search
    /*var locSearch = document.querySelector(".js-branch-modal-search-form");*/
    document.addEventListener("submit", function (e) {
        if (e.target.matches(".js-branch-modal-search-form")) {
            e.preventDefault();
            var inp = document.querySelector(".js-branch-modal-search");
            loadBranchAddress(encodeLocationHTML(inp.value));
        }
    }, false);
    //locSearch.addEventListener("submit", function (e) {
    //    e.preventDefault();
    //    var inp = document.querySelector(".js-branch-modal-search");
    //    loadBranchAddress(encodeLocationHTML(inp.value));
    //}, false);

    function loadBranchAddress(address) {
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({
            'address': address
        }, function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                var coords = results[0].geometry.location;
                sortModalBranches(coords.lat(), coords.lng());

            }
            else {
                console.log("Address Not Found");
            }
        });
    }

    function sortModalBranches(addressLat, addressLon) {
        //Get distance from address for each branch
        document.querySelectorAll(".branches .location").forEach(function (loc) {
            if (loc.getAttribute("data-latlon") != null && loc.getAttribute("data-latlon").indexOf(",") != -1) {
                var sp = loc.getAttribute('data-latlon').split(',');
                lat = Number(sp[0].trim());
                lon = Number(sp[1].trim());
                dist = distance(addressLat, addressLon, lat, lon);
                loc.setAttribute('data-distance', dist);
                loc.querySelector(".js-distance").textContent = (Math.round(dist * 10) / 10) + " Miles Away";
            }
        });

        //Sort items based on distance
        Array.from(document.querySelectorAll(".location[data-distance]")).
            sort(function (a, b) {
                if (Number(a.dataset.distance.replace(" Miles Away", "")) < Number(b.dataset.distance.replace(" Miles Away", ""))) {
                    return -1;
                }
                else {
                    return 1;
                }
            })
            .forEach((item) => item.parentNode.appendChild(item));
    }
}


var script = document.createElement('script');
script.type = 'text/javascript';
script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCUy49KNraQApCQ1hRzhsVSOIr9mZ6Kp9s&libraries=geometry,places&callback=branchInit';
document.body.appendChild(script);